import { useSessionStore } from '@/stores/session';
export default {
  setup() {
    const sessionStore = useSessionStore();
    return {
      sessionStore
    };
  },

  created() {
    /* Start user session */
    this.sessionStore.setLoadingSessionId();
    this.sessionStore.startSession();
    this.topProgressBar = false;
  },

  computed: {
    key() {
      return this.$route.path;
    }

  }
};